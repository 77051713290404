<template>
  <div
    @click="goToNotification()"
    class="tw-flex ln-notification tw-py-4 tw-border-b tw-border-gray-200 tw-px-4 tw-cursor-pointer hover:tw-bg-slate-100"
  >
    <div
      :class="[
        'ln-notification__icon tw-mr-3 tw-flex tw-items-center tw-justify-center',
        iconColor(),
      ]"
    >
      <ShopOutlined
        v-if="notification.sub_type === 'company'"
        :style="{ fontSize: '14px', color: '#096DD9' }"
        :class="notification.read_at === 'null' ? '' : 'tw-opacity-50'"
      />
      <DollarCircleOutlined
        v-else-if="notification.sub_type === 'charge'"
        :style="{ fontSize: '14px', color: '#52C41A' }"
        :class="notification.read_at === 'null' ? '' : 'tw-opacity-50'"
      />
      <UserOutlined
        v-else-if="notification.sub_type === 'user'"
        :style="{ fontSize: '14px', color: '#52C41A' }"
        :class="notification.read_at === 'null' ? '' : 'tw-opacity-50'"
      />
      <CheckCircleOutlined
        v-else-if="
          notification.notifiable_type === 'Task' ||
          notification.sub_type === 'task'
        "
        :style="{ fontSize: '14px', color: '#2F54EB' }"
        :class="notification.read_at === 'null' ? '' : 'tw-opacity-50'"
      />
      <ProfileOutlined
        v-else-if="notification.sub_type === 'order'"
        :style="{ fontSize: '14px', color: '#9254DE' }"
        :class="notification.read_at === 'null' ? '' : 'tw-opacity-50'"
      />
    </div>
    <div class="tw-pr-2">
      <p
        :class="[
          'tw-mb-0',
          notification.read_at === 'null' ? '' : 'tw-text-slate-500',
        ]"
      >
        {{ notification.title }}
      </p>
      <span class="tw-text-xs tw-text-slate-500">{{
        notification.created_at_distance
      }}</span>
    </div>
    <span
      v-if="notification.read_at === 'null'"
      class="tw-block tw-w-2 tw-h-2 tw-bg-red-500 tw-rounded-full tw-ml-auto tw-mt-2"
    >
    </span>
  </div>
</template>

<script lang="ts" setup>
import {
  ShopOutlined,
  DollarCircleOutlined,
  UserOutlined,
  CheckCircleOutlined,
  ProfileOutlined,
} from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import Notification from '~/models/Notification'

const store = useStore()
const props = defineProps({
  notification: {
    required: true,
  },
})

const getLink = () => {
  if (props.notification.notifiable_type === 'Task')
    return `/tasks/${props.notification.notifiable_id}`
  else if (props.notification.sub_type === 'task')
    return `/tasks/${props.notification.sub_id}`
  else if (props.notification.sub_type === 'company')
    return `/companies/${props.notification.sub_id}`
  else if (props.notification.sub_type === 'order')
    return `/orders/${props.notification.sub_id}`
  else if (props.notification.sub_type === 'user')
    return `/users/${props.notification.sub_id}`
  else if (props.notification.sub_type === 'charge')
    return `/charges/${props.notification.sub_id}`
}

const iconColor = () => {
  if (
    props.notification.notifiable_type === 'Task' ||
    props.notification.sub_type === 'task'
  )
    return 'ln-notification__icon__greekblue'
  else if (props.notification.sub_type === 'company')
    return 'ln-notification__icon__blue'
  else if (props.notification.sub_type === 'user')
    return 'ln-notification__icon__cyan'
  else if (props.notification.sub_type === 'order')
    return 'ln-notification__icon__purple'
  else if (props.notification.sub_type === 'charge')
    return 'ln-notification__icon__green'
}

const goToNotification = async () => {
  if (props.notification.read_at === 'null') {
    let notificationData = await useFetchAuth(
      'notifications/' + props.notification.id + '/mark_as_read',
      {
        method: 'PATCH',
      }
    )
    store
      .$db()
      .model(Notification)
      .insertOrUpdate({ data: notificationData.data })
  }
  navigateTo({
    path: getLink(),
  })
}
</script>

<style lang="scss">
.ln-notification {
  color: initial;

  &__icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;

    &__blue {
      background-color: #e6f7ff;
    }
    &__cyan {
      background-color: #e6fffb;
    }
    &__green {
      background-color: #f6ffed;
    }
    &__orange {
      background-color: #fffbe6;
    }
    &__greekblue {
      background-color: #f0f5ff;
    }
    &__purple {
      background-color: #f9f0ff;
    }
  }
}
</style>
